import React from "react"
import Title from "./Title"
import { FaAlignRight, FaAngleDoubleRight } from "react-icons/fa"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"

const query = graphql`
{
  allStrapiJobs(sort: {fields: strapiId, order: DESC}) {
    nodes {
      desc {
        id
        name
      }
      position
      company
      date
    }
  }
  allStrapiWebdevs {
    nodes {
      info
      title
      cathegory
      extra
      desc {
        id
        name
      }
    }
  }
}
`

const Jobs1 = () => {
  const inputRef = React.useRef(null);

  const data = useStaticQuery(query)
  const {
    allStrapiWebdevs: { nodes: webdevs },
  } = data
  const [value, setValue] = React.useState(0)
  const { info, title, extra, desc ,cathegory} = webdevs[value]

  return (
    <section className="section jobs">
      <Title title="Nuestros Servicios" />
      
      <div className="jobs-center">
        {/*btn container*/}
        <div className="btn-container">
          {webdevs.map((item, index) => {
            
            function handleFocus(id){
              //inputRef.current.click();
              //document.getElementById("3").click();
            }

            
            return (
              <button
                key={index}
                onClick={() => setValue(index)}
                ref={inputRef}
                id={index}
                onFocus={handleFocus}
                className={`job-btn ${index === value && "active-btn"}`}
              >
                {item.title}
              </button>
            )
          })}
        </div>

        {/** job info*/}
        <article className="job-info">
          <h3>{title}</h3>
          <h4>{info}</h4>
          <p className="job-date">{extra}</p>
          {desc.map(item => {
            return (
              <div key={item.id} className="job-desc">
                <FaAngleDoubleRight className="job-icon"></FaAngleDoubleRight>
                <p>{item.name}</p>
              </div>
            )
          })}
          
        </article>
        <Link to="/contact" className="btn center-btn">
        Saber más
      </Link>
      </div>
      
    </section>
  )
}

export default Jobs1
