import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Jobs1 from "../components/Jobs1"
import SEO from "../components/SEO"
import Scroll from "../components/Scroll"

// ...GatsbyImageSharpFluid

const JobsPage = ({
  data
}) => {
  return (
    <Layout>
      <Scroll showBelow={250}/>
      <SEO
        title="Servicios"
        description="No lo dejes solo en una idea, comienza tu proyecto con nosotros."
      />
      <section className="projects-page">
        <Jobs1 />
      </section>
    </Layout>
  )
}


export default JobsPage
